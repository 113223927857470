"use client"

import { AppRouterCacheProvider } from "@mui/material-nextjs/v13-appRouter"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { IkhokhaThemeProvider } from "@/builderComponents"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { Provider as JotaiSSRProvider } from "jotai"

const queryClient = new QueryClient()

const Providers = ({ children }: React.PropsWithChildren) => (
	<QueryClientProvider client={queryClient}>
		<JotaiSSRProvider>
			<AppRouterCacheProvider>
				<IkhokhaThemeProvider>{children}</IkhokhaThemeProvider>
			</AppRouterCacheProvider>
		</JotaiSSRProvider>
	</QueryClientProvider>
)

export default Providers
