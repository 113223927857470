"use client"

import { datadogRum } from "@datadog/browser-rum"

if (process.env.NEXT_PUBLIC_ENABLE_DATADOG === "true") {
	datadogRum.init({
		clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "",
		applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? "",
		site: "datadoghq.eu",
		service: process.env.NEXT_PUBLIC_SERVICE_NAME,
		env: process.env.NEXT_PUBLIC_STAGE,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: process.env.NEXT_PUBLIC_VERSION,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 0,
		trackUserInteractions: false,
		trackResources: false,
		trackLongTasks: false,
		defaultPrivacyLevel: "mask-user-input",
		// Specify URLs to propagate trace headers for connection between RUM and backend trace
		allowedTracingUrls: [
			{
				match: (url: string) => {
					if (url.startsWith("https://profile-images.ikhokha")) return false

					return url.includes("ikhokha.red") || url.includes("ikhokha.green") || url.includes("ikhokha.com")
				},
				propagatorTypes: ["datadog"],
			},
		],
	})
}

export default function DatadogInit() {
	// Render nothing - this component is only included so that the init code
	// above will run client-side
	return null
}
